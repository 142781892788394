$(document).ready(function(){
    
    // *********************************************************
    // Hamburger icon
    // *********************************************************
	let navIcon = $('.hamburger');
	let navHover = $('.hamburger__hover');

	navHover.on('click', function(e) {
		navIcon.toggleClass('hamburger--open');

	});

	// *********************************************************
    // Open/Close navigation
	// *********************************************************
	let navigation = $('.navigation');
	let socialNav = $('.social');
	let langNav =  $('.language');
	let header = $('.header');
	let hero = $('.hero');
	let canvasHero = $('.hero__animation');
	let chatBtn = $('.chat-button');
	let scrollBtn = $('.scroll-button');
	let scrollDownBtn = $('.scroll-down');
	let logoMain =  $('.logo');
	
	navHover.on('click', function(e) {
		navigation.toggleClass('navigation--open');
		$('.language--white').toggleClass('language--visible');

		$('.sub-navigation').toggleClass('sub-navigation--nav');

		$('body').toggleClass('body--nav-open');

		// Chat scroll button styl for open nav
		scrollBtn.toggleClass('scroll-button--hide');

		// Chat scroll logo main styl for open nav
		logoMain.toggleClass('logo--hide');

		// Chat button styl for open nav
		chatBtn.toggleClass('chat-button--hide');

		// Scroll down button styl for open nav
		scrollDownBtn.toggleClass('scroll-down--hide');

		// Social style for open nav 
		socialNav.toggleClass('social--nav');
		socialNav.toggleClass('social--hide');

		// Language style for open nav 
		langNav.toggleClass('language--nav');
		
		// Canvas in nav style for open nav 
		canvasHero.toggleClass('hero__animation--nav');
		hero.toggleClass('hero--nav');
		header.toggleClass('header--nav');
	});
	
});

